<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-form-group>
            <b-button
                type="button"
                variant="outline-primary "
                v-on:click="selSeller='all'"
                size="sm"
                class="mr-1 mb-1"
                :disabled="isLoading"
                :class="{'active': selSeller==='all', 'isLoading': isLoading}"
            >
              Все кабинеты
            </b-button>
            <b-button
                type="button"
                variant="outline-primary "
                v-on:click="selSeller=seller"
                v-for="(seller,indx) of allSellers"
                :key="'seller-'+seller+indx"
                size="sm"
                class="mr-1 mb-1"
                :disabled="isLoading"
                :class="{'active': selSeller===seller, 'isLoading': isLoading}"
            >{{ seller }}</b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <b-button
                type="button"
                variant="outline-primary "
                v-on:click="selBrand='all'"
                size="sm"
                class="mr-1 mb-1"
                :disabled="isLoading"
                :class="{'active': selBrand==='all', 'isLoading': isLoading}"
            >
              Все бренды
            </b-button>
            <b-button
                type="button"
                variant="outline-primary "
                v-on:click="selBrand=brand"
                v-for="(brand,indx) of allBrands"
                :key="'brand-'+brand+indx"
                size="sm"
                class="mr-1 mb-1"
                :disabled="isLoading"
                :class="{'active': selBrand===brand, 'isLoading': isLoading}"
            >{{ brand }}</b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group v-if="selBrand">
            <b-button
                type="button"
                variant="outline-primary "
                v-on:click="selModel='all'"
                size="sm"
                class="mr-1"
                :disabled="isLoading"
                :class="{'active': selModel==='all', 'isLoading': isLoading}"
            >
              Все модели
            </b-button>
            <b-button
                type="button"
                variant="outline-primary "
                v-on:click="selModel=model"
                v-for="(model,indx) of allModels"
                :key="'model'+model+indx"
                size="sm"
                class="mr-1 mb-1"
                :disabled="isLoading"
                :class="{'active': selModel===model, 'isLoading': isLoading}"
            >{{ model }}</b-button>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="catalogListFiltered"
              :fields="fields"
              :per-page="perPage"
              :current-page="curPage"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              stacked="md"
              :busy="isLoading"
              tbody-class="acuvue-table"
              style="width: 99%"
              thead-class="acuvue-thead"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <!--          <template #thead-top="">-->
            <!--            <b-tr>-->
            <!--              <b-th colspan="5">Всего с прибылью меньше 50руб. {{reportLog.length}} артикулов</b-th>-->
            <!--              <b-th></b-th>-->
            <!--              <b-th colspan="4" class="text-center">Цена закупочная</b-th>-->
            <!--              <b-th></b-th>-->
            <!--              <b-th colspan="5" class="text-center">Цена на WB</b-th>-->
            <!--              <b-th></b-th>-->
            <!--            </b-tr>-->
            <!--          </template>-->
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>

<!--            <template #cell(createdDate)="{item}">-->

<!--            </template>-->
<!--            <template #cell(feedback)="{item}">-->

<!--            </template>-->

<!--            <template #cell(productValuation)="{item}">-->

<!--            </template>-->
<!--            <template #cell(answer)="row">-->
<!--             </template>-->
<!--            <template #row-details="{item}">-->

<!--            </template>-->
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>
    </b-col>


  </b-row>

</template>

<style scoped>
.b-rating.disabled, .b-rating:disabled {
  background-color:transparent;
  color: #6c757d;
}
a {
  color: #111;
  font-weight: bold;
}
div {
  font-size: 12px;
}
</style>

<script>
// import _ from "lodash"
import dayjs from "dayjs";

// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "wb-feedbacks-catalog",
  components: { },
  data() {
    return {
      catalogList: [],
      fields: [
        { key: 'seller', label: 'Кабинет', sortable: true, sortDirection: 'desc' },
        { key: 'brand', label: 'Бренд', sortable: true, sortDirection: 'desc' },
        { key: 'model', label: 'Оценка', sortable: true, sortDirection: 'desc' },
        { key: 'attr', label: 'Параметры', sortable: true, sortDirection: 'desc' }
      ],
      isLoading: false,
      curPage: 1,
      totalRows: 200,
      perPage: 100,
      allBrands:[],
      allModels:[],
      selBrand:null,
      selModel:null,
      selSeller:null,
      filters: {
        brand: false,
        model: false,
        dio: false
      },
      wbTemplates:[]

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    this.getCatalog()

  },
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getCatalog() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/wb-feedbacks/getCatalog`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.catalogList = resp.items

              this.allSellers=[...new Set(this.catalogList.map(e=>e.seller))]
              this.isLoading=false
              this.totalRows=resp.items.length
            }
            // if (!resp.status) this.alertMsg('Ошибка загрузки выкупов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },

  },
  computed: {
    dayjs() {
      return dayjs
    },
    catalogListFiltered: function () {
      let result = this.catalogList //.slice(0,5)

      if (this.selSeller) result = result.filter(e=> e.seller==this.selSeller)
      if (this.selBrand) result = result.filter(e=> e.brand==this.selBrand)
      if (this.selModel) result = result.filter(e=> e.model==this.selModel)

      return result
    },
  },
  watch:{
    'catalogListFiltered': function () {
      this.totalRows=this.catalogListFiltered.length
    },
    'selBrand' : function () {
      this.allModels=[...new Set(this.catalogListFiltered.map(e=>e.model))]
    },
    'selSeller' : function () {
      this.allBrands=[...new Set(this.catalogListFiltered.map(e=>e.brand))]
    }
  }
};
</script>